import dynamic from 'next/dynamic';

export default {
    Archive: dynamic(() => import('./Archive')),
    ArchiveCalendar: dynamic(() => import('./ArchiveCalendar')),
    ArchiveFact: dynamic(() => import('./ArchiveFact')),
    ArchiveLocal: dynamic(() => import('./ArchiveLocal')),
    ArchiveLoopcheck: dynamic(() => import('./ArchiveLoopcheck')),
    ArchiveTreatment: dynamic(() => import('./ArchiveTreatment')),
    ArchiveVideo: dynamic(() => import('./ArchiveVideo')),
    Calendar: dynamic(() => import('./Calendar')),
    DonationForm: dynamic(() => import('./DonationForm')),
    FactParent: dynamic(() => import('./FactParent')),
    FactPost: dynamic(() => import('./FactPost')),
    HearingCarePost: dynamic(() => import('./HearingCarePost')),
    FrontPage: dynamic(() => import('./FrontPage')),
    Landing: dynamic(() => import('./Landing')),
    LandingDonation: dynamic(() => import('./LandingDonation')),
    LandingHorsellinjen: dynamic(() => import('./LandingHorsellinjen')),
    LandingMembership: dynamic(() => import('./LandingMembership')),
    News: dynamic(() => import('./News')),
    NotFound: dynamic(() => import('./NotFound')),
    MyPages: dynamic(() => import('./MyPages')),
    Page: dynamic(() => import('./Page')),
    PageLoopcheck: dynamic(() => import('./PageLoopcheck')),
    PureHtmlPage: dynamic(() => import('./PureHtmlPage')),
    Search: dynamic(() => import('./Search')),
    ThankYou: dynamic(() => import('./ThankYou')),
    Treatment: dynamic(() => import('./Treatment')),
    Video: dynamic(() => import('./Video')),
    Woocommerce: dynamic(() => import('./Woocommerce')),
};
