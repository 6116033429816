import querystring from 'querystring';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import nextI18NextConfig from '../next-i18next.config.js';
import {
    getPage,
    getRedirect,
    getAllPages,
    WordpressApiResponseError,
} from '../api/wordpress';
import AppLayout from '../layouts/App';

const isProd = process.env.NODE_ENV === 'production';

export default function CatchAllPage({ componentName, componentProps }) {
    return <AppLayout containerName={componentName} {...componentProps} />;
}

// For SSR
export async function getServerSideProps({ req, params, res, locale = 'sv' }) {
    let path = params?.path || [];
    path = path.filter((p) => p !== '[object Object]').join('/');
    // console.log('path', path);

    const i18n = await serverSideTranslations(locale, ['common'], nextI18NextConfig);

    const { host } = req.headers;
    let queryParams = new URL(req.url, `https://${host}`).search;
    if (queryParams.indexOf('?') === 0) {
        queryParams = queryParams.substr(1);
    }
    queryParams = querystring.parse(queryParams);
    // console.log('ssr', params, path, queryParams, host);

    // Try to serve page
    try {
        const {
            json: { componentName, componentProps, redirect, customResponse },
            headers,
        } = await getPage(path, queryParams, {
            headers: {
                cookie: req.headers.cookie,
                host,
            },
        });

        // Forward any cookie we encounter
        const cookies = headers.get('set-cookie');
        if (cookies) {
            res.setHeader('Set-Cookie', cookies);
        }

        if (customResponse) {
            // console.log('custom response', customResponse);
            const { body, body64, contentType } = customResponse;
            res.setHeader('Content-Type', contentType);
            res.statusCode = 200;
            res.write(body64 ? Buffer.from(body64, 'base64') : body);
            res.end();

            return { props: { ...i18n } };
        }

        if (redirect) {
            // console.log('page redirect', redirect);
            const { destination, isPermanent } = redirect;
            return {
                redirect: {
                    destination: destination,
                    permanent: isPermanent,
                },
            };
        }

        return { props: { componentName, componentProps, ...i18n } };
    } catch (err) {
        console.log('get page error', path, queryParams, err);
        if (!(err instanceof WordpressApiResponseError)) {
            throw err;
        }

        if(err.response?.status === 404) {
            // console.log('response 404');
            return { notFound: true };
        }

        // When in development, show wp error page on error
        if (!isProd && err.response?.status >= 500) {
            console.log('response 500 debug error', path, queryParams, err.response);
            let errorProps = {};
            try {
                const html = await err.response.text();
                errorProps = { html };
            } catch(htmlError) {
                console.log('response 500 html error', htmlError);
            }
            return {
                props: {
                    componentName: 'PureHtmlPage',
                    componentProps: errorProps,
                    ...i18n,
                },
            };
        }

        if (err.response?.status >= 500) {
            throw err;
        }
    }

    // Try to serve redirect
    try {
        const { json: redirect } = await getRedirect(path, queryParams, {
            headers: {
                cookie: req.headers.cookie,
                host,
            },
        });
        // console.log('redirect', redirect);
        const { destination, isPermanent } = redirect;
        return {
            redirect: {
                destination: destination,
                permanent: isPermanent,
            },
        };
    } catch (err) {
        console.log('get redirect error', path, queryParams, err);
        if (!(err instanceof WordpressApiResponseError)) {
            throw err;
        }

        if (err.response?.status >= 500) {
            throw err;
        }
    }

    // Serve 404 page
    // console.log('response not found');
    return { notFound: true };
}

// For SSG
/*
export async function getStaticProps({ params, preview, previewData }) {
    params = params || {};
    let path = params.path || [];
    path = path.join("/");

    const { json: pageData } = await getPage(path);
    return { props: pageData }
}

export async function getStaticPaths() {
    const { json: data } = await getAllPages();

    let htmlUrls = data.items.map(x => x.relativeUrl);
    htmlUrls = htmlUrls.filter(x => x);
    htmlUrls = htmlUrls.map(x => x.split("/"));
    htmlUrls = htmlUrls.map(x => x.filter(y => y))
    htmlUrls = htmlUrls.filter(x => x.length)

    const paths = htmlUrls.map(x => (
        { params: { path: x } }
    ));

    return {
        paths: paths,
        fallback: false,
    };
}
*/
